/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import axios from 'axios';
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Profiles from '../components/profiles.vue';

Vue.use(TurbolinksAdapter);

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

Vue.prototype.$http = axios;

document.addEventListener('turbolinks:load', () => {
  let element = document.getElementById('js--select-profiles');
  if (element != null) {
    const app = new Vue({
      el: element,
      components: { Profiles }
    })
  }
})
