<style>
  .table-profiles{
    margin-bottom: 15px;
  }

  .table-profiles table{
    display: table;
    width: 100%;
  }

  .table-profiles > tbody {
    display: table;
    width: 100%;
  }

  hr.panel-splitter{
    margin-bottom: 15px;
  }

  .anc-supplementary-auto {
    height: auto !important;
  }
</style>

<template>
  <div v-if="loading">
    <div class="sk-three-bounce loading js--loading">
      <div class="sk-child sk-bounce1"></div>
      <div class="sk-child sk-bounce2"></div>
      <div class="sk-child sk-bounce3"></div>
    </div>
  </div>
  <div v-else="">
    <table class="table-condensed table-profiles">
      <thead></thead>
      <tbody>
        <tr v-for="profile in profiles">
          <td>
            <div>
              <div class="anc anc--3x" v-bind:class="'anc--' + profile.account_type">
                <div class="anc__network"></div>
                <div class="anc__avatar">
                <div v-if="profile.logo_url">
                  <div class="anc__avatar">
                    <img class="anc__avatar" v-bind:src="profile.logo_url"/>
                  </div>
                </div>
                <div v-else="">
                  <div class="anc__avatar">
                    <div v-bind:class="'anc--' + scope">
                      <div class="anc__section"></div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="anc-supplementary anc-supplementary-auto">
                <div class="anc-supplementary--title">{{ profile.name }}</div>
                <div class="anc-supplementary--info">@{{ profile.username || profile.id }}</div>
              </div>
            </div>
          </td>
          <td>{{ profile.fans_text }}</td>
          <td class="text-center">
            <div class="toggle" v-if="selected.includes(profile.id) || remaining > 0">
              <input class="toggle__checkbox hidden" type="checkbox" v-bind:id="profile.id" v-bind:value="profile.id" v-model="selected" v-on:change="toggleProfile(profile, $event)"></input>
              <label class="toggle__label" v-bind:for="profile.id"></label>
            </div>
            <div v-else="" aria-label="You have maxed out all your social quota and cannot add any more profiles or ad accounts. Please upgrade to the next plan or remove some profiles." class="badge badge-pill badge-primary hint__info hint--left pull-right">
              <i class="fa fa-lock"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="profiles.length === 0">
      <div class="alert-danger p-3">Sorry, the connected account doesn't have any profile to analyse. Please, connect with another account.</div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div v-if="profilesCount > 0 && display_remaining">
          <hr class="panel-splitter"/>
          <p>You can add {{ remaining }} more profiles.</p>
          <br/>
          <div class="text-right primary-action-button">
            <slot></slot>
            <div class="clearfix"></div>
          </div>
        </div>
        <div v-else="">
          <slot name="warning"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        loading: true,
        selected: [],
        profiles: [],
        remaining: -1
      }
    },
    mounted: function() {
      this.$http
        .get('/' + this.scope + '/search/' + this.id + '.json')
        .then(
            response => {
              this.loading = false
              this.profiles = response.data.profiles
              this.remaining = response.data.profiles_remaining
              this.selected = response.data.profiles_selected
            });
    },
    computed: {
      selectAll: {
        get: function () {
          return this.profiles ? this.selected.length == this.profiles.length : false;
        },
        set: function (value) {
          var selected = [];

          if (value) {
            this.profiles.forEach(function (profile) {
              selected.push(profile.id);
            });
          }
          this.selected = selected;
        }
      },
      profilesCount: function () {
        return this.selected.length;
      }
    },
    methods: {
      toggleProfile(profile, e) {
        if (e.target.checked) {
          this.$http
            .post('/' + this.scope + '/settings/' + this.id + '/profiles/' + profile.account_type, {
              profile: { id: profile.id, username: profile.username }
            })
            .then(function (response) {
              console.log(response);
            });
          this.remaining--;
        } else {
          this.$http
            .delete('/' + this.scope + '/settings/' + this.id + '/profiles/' + e.target.id)
            .then(function (response) {
              console.log(response);
            });
          this.remaining++;
        };
      }
    },
    props: {
      display_remaining: {
        type: Boolean,
        default: true
      },
      id: {
        type: String,
        required: true
      },
      scope: {
        type: String,
        required: true
      },
    }
  };
</script>
